.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    border-radius: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    background-color: rgb(141, 131, 131);
    font-size: 13px;
    font-weight: 600;
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    border-radius: 5px;
    height: 22px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: #ef7d1a;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }
  

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 20px 20px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  /* margin-top: 20px; */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* Description: Master CSS file */

/*****************************************
Table Of Contents:
- General Styles
- Navigation
- Home
- Information
- Button
- Services
- Plans
- Testimonials
- Newsletter
- Back To Top Button
- Extra Pages
- Media Queries
******************************************/

/**************************/
/*     General Styles     */
/**************************/
:root {
  --primary: #2b6694;
  --primary-light: #385a6a;
  --secondary: #59bf00;
  --tertiary: #2b6694;
  --gradient: linear-gradient(to right, #2b6694, #59bf00);
}

body {
  background-color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 60px;
  font-weight: 500;
}

.modText {
  color: #00dabf;
  z-index: 10;
  font-size: 60px;
}

button {
  font-family: sans-serif;
}

p,
ul,
h4 {
  margin: 0;
  padding: 0;
}

a {
  color: white;
  text-decoration: none;
}

li {
  list-style-type: none;
}

/* Section Background */
.home,
.about,
.services,
.plans,
.work,
.contact {
  /* height: 80vh; */
  position: relative;
}

.services,
.work,
.contact,
.testimonial,
.footer {
  background-color: #17344b;
}

.about,
.plans,
.company,
.newsletter,
.location {
  background-color: var(--primary-light);
}

.bottom {
  background-color: black;
}

/* Gradient Border And Background On Icons */
.home_text,
.home .fas,
.home .fab,
.plans .far,
.information .fas,
.work .fas,
.services .fas,
.location .far,
.location .fas {
  padding: 15px 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2b6694),
    to(#59bf00)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-nav a:hover,
.footer a:hover,
.footer .fab:hover {
  cursor: pointer;
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#navItems{
  background-color: #e5e5e5;
  width:100%;
  float:right;
}

.boarduser .nav-link {
  color: #6c757c;
}
.boarduser .nav-link.active {
  color: #6c757c;
  font-weight: 700;
}
.boarduser .nav-link:hover {
  color: #59bf00;
}


.showHr .form-switch{
height: 20px;
width: 70px;
}

.showHr .form-check-input {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #59bf00;
  border-color: #59bf00
}
.showStatus .form-switch{
height: 20px;
width: 70px;
}

.showStatus .form-check-input {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
}
.showHr .form-check-input:checked {
  background-color: #2b6694;
  border-color: #2b6694
}

.form-check-input {
cursor: pointer;
}



/**********************/
/*     Navigation     */
/**********************/
.navbar {
  height: 90px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 0.875rem;
  background-color: #e5e5e5;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.025);
}

.navbar .navbar-brand {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar .logo-image img {
  height: 32px;
  background-color: var(--primary);
}

.navbar .logo-text {
  color: #222;
  font-weight: 500;
  line-height: 1rem;
  font-size: 1.575rem;
  text-decoration: none;
}

.offcanvas-collapse {
  position: fixed;
  top: 2.75rem; /* adjusts the height between the top of the page and the offcanvas menu */
  bottom: 0;
  left: 100%;
  width: 100%;
  overflow-y: auto;
  visibility: hidden;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: var(--primary);
  transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.offcanvas-collapse.open {
  visibility: visible;
  transform: translateX(-100%);
}

.navbar .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.navbar .dropdown-menu {
  border: none;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: var(--primary);
}

.navbar .dropdown-item {
  color: #222;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  padding-top: 0.625rem;
  text-decoration: none;
  padding-bottom: 0.625rem;
}

.navbar .dropdown-item:hover {
  background-color: var(--primary);
}

.navbar .dropdown-divider {
  width: 100%;
  height: 1px;
  border: none;
  margin: 0.5rem auto 0.5rem auto;
  background-color: var(--primary-light);
}

.navbar .nav-item .nav-link {
  color: #222;
  text-decoration: none;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  transition: all 0.2s ease;
}

.navbar .fa-stack {
  width: 2em;
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

.navbar .fa-stack-2x {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#59bf00),
    to(#2b6694)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s ease;
}

.navbar .fa-stack-1x {
  color: #222;
  transition: all 0.2s ease;
}

.navbar .fa-stack:hover .fa-stack-2x {
  color: var(--primary);
}

.navbar .fa-stack:hover .fa-stack-1x {
  color: var(--primary);
}

.navbar .navbar-toggler {
  padding: 0;
  border: none;
  font-size: 1.25rem;
}

/****************/
/*     Home     */
/****************/
.home {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.para {
  width: 50%;
}

.para-light {
  opacity: 0.7;
}

/***********************/
/*     Information     */
/***********************/
.information .container-fluid .row div:first-child {
  background-color: #17344b;
}

.information .container-fluid .row div:last-child {
  background-color: #e5e5e6;
}

.information .container-fluid .row div:nth-child(2) {
  background-color: #385a6a;
}

/***********************/
/*     Information     */
/***********************/
.about .img-fluid {
  border-radius: 50%;
}

/******************/
/*     Button     */
/******************/
.btn {
  color: white;
  border-radius: 0;
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
  box-shadow: 5px 5px 0px 0px var(--secondary);
}

.btn:hover {
  color: white;
  box-shadow: none;
  border-radius: 0;
  padding: 10px 30px;
  transform: translate(5px, 5px);
}

.btn2 {
  color: white;
  border-radius: 0;
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
  box-shadow: 5px 5px 0px 0px var(--secondary);
  font-weight: bolder;
  background-color: #2b6694;
}

.btn2:hover {
  color: #2b6694;
  background-color: #59bf00;
  box-shadow: none;
  border-radius: 0;
  padding: 10px 30px;
  transform: translate(5px, 5px);
  font-weight: bolder;
}

.btn1 {
  color: white;
  border-radius: 5%;
  border-width: 0;
  padding: 0;
  /* border-image-slice: 1; */
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
  box-shadow: 0px 0px 0px 0px var(--secondary);
  font-weight: bolder;
  background-color: #59bf00;
}

.btn1:hover {
  color: #59bf00;
  background-color: #2b6694;
  box-shadow: none;
  border-radius: 5%;
  /* padding: 10px 30px; */
  /* transform: translate(5px, 5px); */
  font-weight: bolder;
}

.btn-switch {
  color: white;
  border-radius: 5%;
  border-width: 0;
  padding: 0;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
  box-shadow: 0px 0px 0px 0px var(--secondary);
  font-weight: bolder;
  background-color: #59bf00;
}

.btn-switch:hover {
  color: #59bf00;
  background-color: #2b6694;
  box-shadow: none;
  border-radius: 5%;
  font-weight: bolder;
  border: 3px, solid, orangered;
}

.bg-trans {
  background-color: #212d37;
}
.text-trans {
  color: #59bf00;
}

/* Email - Newsletter Button */
.btn-secondary {
  color: black;
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
}

.btn-secondary:hover {
  color: black;
  padding: 10px 30px;
  background-image: var(--gradient);
}
.btn-drop {
  color: black;
  border-width: 2px;
  padding: 0px 5px;
  background-image: none;
  background: transparent;
  box-shadow: none;
}

.btn-drop:hover {
  background-image: var(--gradient);
  padding: 0px 5px;
  transform: none;
}

/* Other Pages Button */
.btn-tertiary {
  color: black;
  border-radius: 0;
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
  box-shadow: 5px 5px 0px 0px var(--secondary);
}

.btn-tertiary:hover {
  color: black;
  box-shadow: none;
  border-radius: 0;
  padding: 10px 30px;
  transform: translate(5px, 5px);
}

/********************/
/*     Services     */
/********************/
.services .card {
  padding: 10px;
  border-radius: 0;
  border: 2px solid white;
  height: 100%;
}

.services .card:hover {
  border: 10px solid;
  transform: scale(1.01);
  border-image-slice: 1;
  border-width: 2px;
  /* border-image-source: var(--gradient); */
  border-color: var(--gradient);
  box-shadow: 5px 5px 0px 0px #59bf00;
}

/*****************/
/*     Plans     */
/*****************/
.plans .card {
  padding: 20px;
  border: 2px solid white;
}

/************************/
/*     Testimonials     */
/************************/
.slider-1 {
  padding-top: 8.25rem;
  padding-bottom: 8.5rem;
}

.slider-1 .section-title {
  text-align: center;
}

.slider-1 .h2-heading {
  text-align: center;
  margin-bottom: 3rem;
}

.testimonial-card {
  height: 300px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: var(--gradient);
}

.slider-1 .slider-container {
  position: relative;
}

.slider-1 .swiper-container {
  width: 86%;
  position: static;
}

.slider-1 .swiper-button-prev,
.slider-1 .swiper-button-next {
  color: var(--primary);
}

.slider-1 .swiper-button-prev:focus,
.slider-1 .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}

.slider-1 .swiper-button-prev {
  left: -14px;
  background-size: 18px 28px;
}

.slider-1 .swiper-button-next {
  right: -14px;
  background-size: 18px 28px;
}

.slider-1 .card {
  border: none;
  position: relative;
  background-color: transparent;
}

.slider-1 .card-image {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-bottom: 1.25rem;
}

.slider-1 .card-body {
  padding: 0;
}

.slider-1 .testimonial-text {
  margin-bottom: 0.75rem;
}

.slider-1 .testimonial-author {
  margin-bottom: 0;
  color: #252c38;
}

.slider-1 .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

/* Dropdown Menu */
.navbar .dropdown .dropdown-menu {
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@-webkit-keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**********************/
/*     Newsletter     */
/**********************/
.form-control-input,
.form-control-textarea {
  width: 100%;
  appearance: none;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0.775rem;
  padding-bottom: 0.775rem;
}

/*--------------------------------------------------------------
# Whatsapp sticky icon
--------------------------------------------------------------*/

#whatsapp-sticky {
  z-index: 99;
  position: fixed;
  border: none;
  outline: none;
  cursor: pointer;
  left: 20px;
  bottom: 50px;
  border-radius: 50%;
}

#whatsapp-sticky:link {
  text-decoration: none;
  color: #59bf00;
}

/* visited link */
#whatsapp-sticky:visited {
  text-decoration: none;
  color: #59bf00;
}

/* mouse over link */
#whatsapp-sticky:hover {
  text-decoration: none;
  color: #e99042;
}

/* selected link */
#whatsapp-sticky:active {
  text-decoration: none;
  color: #59bf00;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #37517e;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #37517e;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/******************************/
/*     Back To Top Button     */
/******************************/
#myBtn {
  z-index: 99;
  right: 20px;
  width: 52px;
  height: 52px;
  bottom: 20px;
  border: none;
  outline: none;
  display: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  background-color: #323137;
}

#myBtn:hover {
  background-color: #0f0f11;
}

#myBtn img {
  width: 18px;
  margin-left: 0.125rem;
  margin-bottom: 0.25rem;
}

/***********************/
/*     Extra Pages     */
/***********************/
.ex-header {
  padding-top: 8.5rem;
  padding-bottom: 4rem;
  background-color: var(--primary);
}

.ex-header h1 {
  color: #fff;
}

.ex-basic-1 .list-unstyled .fas {
  font-size: 0.375rem;
  line-height: 1.625rem;
}

.ex-basic-1 .list-unstyled .flex-grow-1 {
  margin-left: 0.5rem;
}

.ex-basic-1 .text-box {
  padding: 1.25rem 1.25rem 0.5rem 1.25rem;
  background-color: #f7f9fd;
}

.ex-cards-1 .card {
  border: none;
  background-color: transparent;
}

.ex-cards-1 .card .fa-stack {
  width: 2em;
  font-size: 1.125rem;
}

.ex-cards-1 .card .fa-stack-2x {
  color: var(--secondary);
}

.ex-cards-1 .card .fa-stack-1x {
  width: 2em;
  color: #ffffff;
  font-weight: 700;
  line-height: 2.125rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 {
  margin-left: 2.25rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 h5 {
  margin-top: 0.125rem;
  margin-bottom: 0.5rem;
}

 #modCard{
   width:50%;
 }

 #supCard{
   width:75%;
 }




/*************************/
/*     Media Queries     */
/*************************/
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .testimonial-card {
    height: 400px;
  }
 
}

/*************************/
/*     Media Queries     */
/*************************/
@media only screen and (max-width: 1024px) {
  .services,
  .work,
  .testimonial,
  .about,
  .contact,
  .plans {
    height: 110%;
  }
  #modCard{
    width:100%;
  }
  
}
@media (max-width: 992px) {
  #navItems{
    background-color: #2b6694;
    width:50%;
    float:right;
  }
 
  }
 


@media (min-width: 992px) {
  .slider-1 .swiper-container {
    width: 92%;
    
  }

  .slider-1 .swiper-button-prev {
    left: -16px;
    width: 22px;
    background-size: 22px 34px;
  }

  .slider-1 .swiper-button-next {
    right: -16px;
    width: 22px;
    background-size: 22px 34px;
  }

  /* Navigation */
  .navbar {
    box-shadow: none;
    transition: all 0.2s;
    padding-top: 1.75rem;
    background-color: transparent;
  }

  .navbar.top-nav-collapse {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: var(--primary);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.025);
  }

  .offcanvas-collapse {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    overflow-y: visible;
    visibility: visible;
  }

  .offcanvas-collapse.open {
    transform: none;
  }

  .navbar .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar .nav-item .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }


  .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
    color: #222;
  }


  .navbar .dropdown-menu {
    padding-top: 0.75rem;
    padding-bottom: 0.875rem;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
  }

  .navbar .dropdown-divider {
    width: 90%;
  }

  .navbar .social-icons {
    margin-left: 0.5rem;
  }

  .navbar .fa-stack {
    margin-right: 0;
    margin-left: 0.25rem;
  }

  
}

.form-check label {
  margin: 0px;
  padding-left: 5px;
}

.btn-close {
  margin-left: 20px !important;
}

@media only screen and (max-width: 540px) {
  h1 {
    font-size: 30px;
  }

  .para {
    width: 100%;
  }

  .swiper-container {
    width: 92%;
  }

  .swiper-button-prev {
    left: -16px;
    width: 22px;
    background-size: 22px 34px;
  }

  .swiper-button-next {
    right: -16px;
    width: 22px;
    background-size: 22px 34px;
  }
}

@media (max-width: 400px) {
  #navItems{
    background-color: #2b6694;
    width:75%;
    float:right;
  }

  }

  @media (max-width: 270px) {
    #navItems{
      background-color: #2b6694;
      width:100%;
      float:right;
    }
  
    }